
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "1c93e8da-981a-414d-aa36-36be0e99ab9a"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/lib/ui/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
